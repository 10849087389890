import React, { useState } from 'react';
import { Radio } from 'antd';
import '../css/PaymentMethodSelection.css'; 

const PaymentMethodSelection = ({ onChange }) => {
    const [selectedMethod, setSelectedMethod] = useState('creditCard');
  
    const handlePaymentMethodChange = (e) => {
      const method = e.target.value;
      setSelectedMethod(method);
      onChange(method);
    };
  
    return (
      <div className="payment-method-selection">
        <h3>Select Payment Method:</h3>
        <Radio.Group
          onChange={handlePaymentMethodChange}
          value={selectedMethod}
          className="payment-method-radio-group"
        >
          <Radio value="creditCard" className="payment-method-radio">
            Credit Card
          </Radio>
          <Radio value="paypal" className="payment-method-radio">
            PayPal
          </Radio>
          <Radio value="other" className="payment-method-radio">
            Other
          </Radio>
        </Radio.Group>
      </div>
    );
  };

export default PaymentMethodSelection;
