import React from 'react';
import { Card, Col, Row } from 'antd';

const { Meta } = Card;

const FeaturedProducts = () => {
  return (
    <Row gutter={16}>
      {/* Map through your products array and display them as below */}
      <Col span={8}>
        <Card
          hoverable
          cover={<img alt="example" src="url_to_your_product_image" />}
        >
          <Meta title="Product Name" description="Product Description" />
        </Card>
      </Col>
      {/* Add more products as needed */}
    </Row>
  );
};

export default FeaturedProducts;
