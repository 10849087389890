import React from 'react';
import { Layout, Row, Col } from 'antd';
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ color: '#4B2D26', textAlign: 'center' }}>
      <Row gutter={16}>
        <Col span={8}>
          <h3>Contact Us</h3>
          <p></p>
          <p>+977 9818311488</p>
          <p>anup.singh2071@gmail.com</p>
        </Col>
        <Col span={8}>
          <h3>Quick Links</h3>
          <p><a href="/privacy" style={{ color: '#4B2D26' }}>Privacy Policy</a></p>
          <p><a href="/terms" style={{ color: '#4B2D26' }}>Terms of Service</a></p>
          <p><a href="/contact" style={{ color: '#4B2D26' }}>Contact</a></p>
        </Col>
        <Col span={8}>
          <h3>Follow Us</h3>
          <FacebookOutlined style={{ fontSize: '24px', color: '#4B2D26', marginRight: '16px' }} />
          <InstagramOutlined style={{ fontSize: '24px', color: '#4B2D26', marginRight: '16px' }} />
          <TwitterOutlined style={{ fontSize: '24px', color: '#4B2D26' }} />
        </Col>
      </Row>
      <p style={{ marginTop: '16px' }}>© 2023 by Anupriya Creations. All rights reserved.</p>
    </Footer>
  );
};

export default AppFooter;
