import React from 'react';
import { Carousel, Card, Avatar } from 'antd';
import '../css/Testimonials.css';

const testimonials = [
  {
    name: "Alice",
    feedback: "Absolutely love these candles! They smell great and look beautiful too.",
    avatar: "https://picsum.photos/id/64/200/300"
  },
  {
    name: "Bob",
    feedback: "The chocolates are delicious and the packaging is extremely elegant. Perfect for gifts!",
    avatar: "https://picsum.photos/id/1/200/300"
  },
  {
    name: "John Doe",
    feedback: "Loved the candles! They are absolutely beautiful and smell wonderful.",
    avatar: "https://picsum.photos/id/2/200/300"
  },
  {
    name: "Jane Smith",
    feedback: "The chocolates are delicious and the packaging is extremely elegant. Perfect for gifts!",
    avatar: "https://picsum.photos/id/3/200/300"
  }
];

const Testimonials = () => {
  return (
    <div className="testimonials" style={{ background: '#fffeed', padding: '50px' }}>
      <h2 style={{ color: '#4B2D26', textAlign: 'center' }}>What Our Customers Say</h2>
      <Carousel autoplay>
        {testimonials.map((testimonial, index) => (
          <div key={index}>
            <Card
              title={<span style={{ color: '#4B2D26' }}>{testimonial.name}</span>}
              extra={<Avatar src={testimonial.avatar} alt={testimonial.name} />}
              style={{ width: 300, margin: 'auto', borderColor: '#FFD700' }}
            >
              <p style={{ color: '#4B2D26' }}>{testimonial.feedback}</p>
            </Card>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
