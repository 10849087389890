import React, { createContext, useContext, useReducer } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      // Check if the item is already in the cart
      const existingItemIndex = state.findIndex(
        (item) => item.product.id === action.product.id
      );

      // If it is, update the quantity
      if (existingItemIndex > -1) {
        const updatedCart = [...state];
        updatedCart[existingItemIndex].quantity += 1;
        return updatedCart;
      }

      // If it's not, add a new item
      return [...state, { product: action.product, quantity: 1 }];

    case 'REMOVE_FROM_CART':
        return state.filter(item => item.product.id !== action.productId);

    case 'INCREMENT_QUANTITY':
        return state.map((item) => {
            if (item.product.id === action.productId) {
            return { ...item, quantity: item.quantity + 1 };
            }
            return item;
        });
      
    case 'DECREMENT_QUANTITY':
    return state.map((item) => {
        if (item.product.id === action.productId) {
        return { ...item, quantity: item.quantity - 1 };
        }
        return item;
    }).filter((item) => item.quantity > 0);
      
    default:
        return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, []);

  const addToCart = (product) => {
    dispatch({ type: 'ADD_TO_CART', product });
  };

  const removeFromCart = (productId) => {
    dispatch({ type: 'REMOVE_FROM_CART', productId });
  };

  const incrementQuantity = (productId) => {
    dispatch({ type: 'INCREMENT_QUANTITY', productId });
  };

  const decrementQuantity = (productId) => {
    dispatch({ type: 'DECREMENT_QUANTITY', productId });
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, incrementQuantity, decrementQuantity }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
