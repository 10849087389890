import React from 'react';
import { Button, Typography } from 'antd';
import "../css/Hero.css"

const { Title, Paragraph } = Typography;

const Hero = () => {
    return (
      <div className="hero">
        <Typography className="hero-text">
          <Title>Welcome to Anupriya Creations</Title>
          <Paragraph>
            Explore our exquisite range of decorative candles and artisan chocolates.
          </Paragraph>
          <Button type="primary" size="large">Shop Now</Button>
        </Typography>
      </div>
    );
  };

export default Hero;
