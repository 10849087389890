import React from 'react';

const Blog = () => {
  return (
    <div>
      <h1>Blog</h1>
      {/* Display blog posts here */}
    </div>
  );
};

export default Blog;