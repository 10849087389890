import React from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/Hero';
import FeaturedProducts from '../components/FeaturedProducts';
import Testimonials from './Testimonials';
import Footer from '../components/Footer';
import ProductPage from './ProductPage';
import CategoryList from '../components/CategoryList';
import {Layout } from 'antd';

const { Content } = Layout;

const Homepage = () => {
  return (
    <div>
    <div>
      <Navbar />
      <Content>
        {/* <HeroSection /> */}
        {/* <CategoryList /> */}
        <ProductPage />
        {/* <Testimonials /> */}
      </Content>
      <Footer />
    </div>
    </div>
  );
};

export default Homepage;