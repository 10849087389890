import React, { useState } from 'react';
import { Card, Row, Col, Button, Pagination } from 'antd';
import products from '../data/products.json'; 
import { useHistory } from 'react-router-dom';
import '../css/ProductPage.css';

const { Meta } = Card;

const ProductPage = () => {
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(12);

    // Get current products
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Function to handle card click
    const handleCardClick = (productId) => {
        history.push(`/product/${productId}`);
        };

  return (
    <div className="products" style={{ padding: '30px'}}>
      <Row gutter={16}>
        {currentProducts.map((product, index) => (
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <Card
              hoverable
              style={{ width: '100%', marginBottom: '30px'}}
              cover={<img alt={product.name} src={product.image} />}
              onClick={() => handleCardClick(product.id)}
            >
              <Meta 
                title={product.name} 
                description={''} 
            />
            <small>NRs.</small> <strong style={{padding: '1rem 0', fontSize: '1.5rem'}}>{product.price} </strong>
              <p>{product.caption}</p>
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination 
        current={currentPage}
        total={products.length}
        pageSize={productsPerPage}
        onChange={paginate}
        style={{ textAlign: 'center', marginBottom: '20px' }}
      />
    </div>
  );
};

export default ProductPage;