import React from 'react';
import '../css/CartPage.css';

const OrderPage = () => {
  return (
    <div>
      <div className='cart-form'>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfpxih1WbJwmvz9jzsvvdFx8cJ5J30hcBst3KpYeb-H1m8Zhg/viewform?embedded=true" width="640" height="2882" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
    </div>
  );
};

export default OrderPage;