import logo from './logo.svg';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Homepage from './pages/Homepage';
import ProductPage from './pages/ProductPage';
import AboutUs from './pages/AboutUs';
import ContactPage from './pages/ContactPage';
import Blog from './pages/Blog';
// import FAQs from './pages/FAQs';
import Testimonials from './pages/Testimonials';
import ProductDetailPage from './pages/ProductDetailPage';
import { CartProvider } from './contexts/CartContext';
import CartPage from './pages/CartPage';
import OrderPage from './pages/OrderPage';

const App = () => {
  return (
    <Router>
      <CartProvider>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/products" component={ProductPage} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/blog" component={Blog} />
        {/* <Route path="/faqs" component={FAQs} /> */}
        <Route path="/testimonials" component={Testimonials} />
        <Route path="/product/:productId" component={ProductDetailPage} />
        <Route path="/cart" component={CartPage} />
        <Route path='/order' component={OrderPage}/>
      </Switch>
      </CartProvider>
    </Router>
  );
};

export default App;
