import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {Layout } from 'antd';
import { HomeOutlined, GiftOutlined, InfoCircleOutlined, PhoneOutlined, ReadOutlined, QuestionCircleOutlined, StarOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useCart } from '../contexts/CartContext';

const { Header } = Layout;

const Navbar = () => {
    const { cart } = useCart();

  return (
    <Header>
    <Menu mode="horizontal" theme="light" style={{ backgroundColor: '#FCFCFC' }}>
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <Link to="/">Home</Link>
      </Menu.Item>
      {/* <Menu.Item key="products" icon={<GiftOutlined />}>
        Products
      </Menu.Item>
      <Menu.Item key="about" icon={<InfoCircleOutlined />}>
        About Us
      </Menu.Item>
      <Menu.Item key="contact" icon={<PhoneOutlined />}>
        Contact
      </Menu.Item>
      <Menu.Item key="blog" icon={<ReadOutlined />}>
        Blog/News
      </Menu.Item>
      <Menu.Item key="faqs" icon={<QuestionCircleOutlined />}>
        FAQs
      </Menu.Item>
      <Menu.Item key="testimonials" icon={<StarOutlined />}>
        Testimonials
      </Menu.Item> */}
      <Menu.Item key="cart" className="cart-menu" icon={<ShoppingCartOutlined />}>
        <Link to="/cart" style={{ marginRight: '20px' }}>
          Cart ({cart.length})
        </Link>
      </Menu.Item>
    </Menu>
    </Header>
  );
};

export default Navbar;
