import '../css/CartPage.css';
import React, { useState } from 'react';
import { List, Card, Button, Typography, Row, Col, Form, Input, Layout } from 'antd';
import { useCart } from '../contexts/CartContext';
import Navbar from '../components/Navbar';
import PaymentMethodSelection from '../components/PaymentMethodSelection';
// import Footer from '../components/Footer';

const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Item } = Form;

const CartPage = () => {
    const { cart, removeFromCart, incrementQuantity, decrementQuantity } = useCart();
    const totalPrice = cart.reduce((acc, item) => acc + item.product.price * item.quantity, 0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('creditCard'); // State to track the selected payment method
    const [form] = Form.useForm();


    const handleCheckout = () => {
        // Handle the checkout logic, including user details and payment method
        const userDetails = form.getFieldsValue();
        console.log('User details:', userDetails);
        console.log('Selected payment method:', selectedPaymentMethod);
    
        // Perform further actions like API calls, validation, etc.
      };



    const handlePaymentMethodChange = (method) => {
        setSelectedPaymentMethod(method);
    };

    return (
        <Layout>
          <Header>
            <Navbar/>
          </Header>
          <Content>
          {true && <Row gutter={16}>
              <Col xs={24} md={16}>
                {
                  cart.map((item) => <Card
                    className='cart-products-card'
                    title={item.product.name}
                    style={{ width: '100%', marginBottom: '20px', borderRadius: '15px' }}
                    >
                    <Row type="flex">
                    <Col>
                      <img 
                          src={item.product.image} 
                          alt={item.product.name} 
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                      />
                    </Col>
                    <Col>
                      <p>Price: NRs. {item.product.price}</p>
                    </Col>
                    <p>Quantity: {item.quantity}</p>
                    <Button type="link" onClick={() => incrementQuantity(item.product.id)}>+</Button>
                    {item.quantity === 1 ? (
                    <Button type="link" onClick={() => removeFromCart(item.product.id)}>Remove</Button>
                    ) : (
                    <Button type="link" onClick={() => decrementQuantity(item.product.id)}>-</Button>
                    )}
                    </Row>
                  </Card>

                  )
                }
              </Col>
              <Col xs={24} md={8}>
              {/* Payment Method Selection Component */}
              <PaymentMethodSelection onChange={handlePaymentMethodChange} />

              {/* Display Selected Payment Method */}
              <div style={{ marginTop: '20px' }}>
                  <h3>Selected Payment Method:</h3>
                  <p>{selectedPaymentMethod}</p>
              </div>

                                    {/* Delivery Details Form */}
            <Form form={form} onFinish={handleCheckout} style={{ marginTop: '20px' }}>
              <Title level={4}>Delivery Details</Title>
              <Item label="" name="fullName" rules={[{ required: true, message: 'Please enter your full name' }]}>
                <Input placeholder="Full name" />
              </Item>
              <Item label="" name="address" rules={[{ required: true, message: 'Please enter your address' }]}>
                <Input placeholder="Delivery address" />
              </Item>
              <Item label="" name="phoneNumber" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                <Input placeholder="Phone number" />
              </Item>
              <Item label="" name="instructions" rules={[{ required: false, message: 'Please provide instructions regarding your location. (if any)' }]}>
                <Input placeholder="Instructions(if any)" />
              </Item>
              <Item>

              {/* Checkout Button */}
                <Button type="primary" htmlType="submit">
                  Proceed to Checkout
                </Button>
              </Item>
            </Form>
              </Col>
          </Row>}
          </Content>
          {/* <Footer/> */}
    </Layout>
  );
};

export default CartPage;
