import React, { useState } from "react";
import { Card, Button, Row, Col, Modal } from "antd";
import { useParams } from "react-router-dom";
import products from "../data/products.json";
import scents from "../data/scents.json";
import "../css/ProductDetailPage.css";
import Navbar from "../components/Navbar";
import { useHistory } from 'react-router-dom';
import {getMultipleRandom} from '../utils/helpers'
import { useCart } from '../contexts/CartContext';

const { Meta } = Card;

const ProductDetailPage = () => {
  let { productId } = useParams();
  const { addToCart } = useCart(); 
  productId = parseInt(productId, 10);

  const product = products.find((p) => p.id === productId);
  const history = useHistory();
  // States
  const [selectedScent, setSelectedScent] = useState({
    name: "Lavender",
    color: "#DFC5FE",
  });
  const [selectedVariant, setSelectedVariant] = useState({});
  const [selectedColor, setSelectedColor] = useState("#0000FF");
  const [colorModalVisible, setColorModalVisible] = useState(false);
  const [scentModalVisible, setScentModalVisible] = useState(false);
  const [added, setAdded] = useState(false);

  // Event handlers
  const showColorModal = () => {
    setColorModalVisible(true);
  };

  const hideColorModal = () => {
    setColorModalVisible(false);
  };

  const showScentModal = () => {
    setScentModalVisible(true);
  };

  const hideScentModal = () => {
    setScentModalVisible(false);
  };


  const handleScentClick = (scent) => {
    setSelectedScent(scent);
    hideScentModal();
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
    hideColorModal();
  };

  const handleSimilarProductClick = (productId) => {
    history.push(`/product/${productId}`);
    };

  const viewCart = () => {
    history.push(`/cart`);
  };

  const handleAddToCart = (product) => {
    if(!added){
      addToCart(product);
      setAdded(true); 
    }
  };

  const renderColorSwatch = (color) => (
    <Card
      hoverable
      className={`color-option ${selectedColor === color ? "selected" : ""}`}
      style={{
        backgroundColor: color.toLowerCase(),
      }}
      onClick={showColorModal}
    />
  );

  const renderScentCard = (scent) => (
    <Col key={scent.id} span={6} style={{ marginBottom: "16px" }}>
      <Card
        hoverable
        className={`scent-option ${selectedScent.name === scent.name ? "selected" : ""}`}
        style={{ backgroundColor: scent.color}}
        cover={scent.image && <img alt={scent.name} src={scent.image} />}
        onClick={() => handleScentClick(scent)}
      >
      </Card>
      <div style={{textAlign: 'center'}}>{scent.name}</div>
    </Col>
  );

  return (
    <>
      <Navbar />
      <div className="product-detail" style={{ padding: "30px" }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8}>
            <div className="zoom-image-container">
              <img
                alt={product.name}
                src={product.image}
                className="product-image"
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card
              className="product-detail-card"
            >
              <Meta title={product.name} description={""} />
              <small>NRs.</small>{" "}
              <strong style={{ padding: "1rem 0", fontSize: "1.5rem" }}>
                {product.price}{" "}
              </strong>
              <p>{product.description}</p>
            </Card>
            <br />
            <br />

            <Card className="product-detail-card">
              <Meta title="Personalize your candle" description={""}/>
              <br/>
              <Row gutter={16} type="flex">
                <Col>
                  {selectedScent && (
                    <>
                      {"Scent"}
                      <Card
                        hoverable
                        style={{
                          backgroundColor: selectedScent.color,
                          borderRadius: "50%",
                        }}
                        cover={
                          selectedScent.image && (
                            <img alt={selectedScent.name} src={selectedScent.image} />
                          )
                        }
                        onClick={showScentModal}
                      ></Card>
                      {selectedScent.name}
                    </>
                  )}
                </Col>
                <Col>
                  {selectedColor && (
                    <>
                      {"Color"}
                      {renderColorSwatch(selectedColor)}
                      <p>{selectedColor}</p>
                    </>
                  )}
                </Col>
              </Row>

              {/* Color modal */}
              <Modal
                title="Available Colors"
                visible={colorModalVisible}
                onCancel={hideColorModal}
                footer={null}
              >
                <Row gutter={16} style={{alignItems:'center'}}>
                  {product.colors.map((color) => (
                    <Col
                      key={color}
                      span={6}
                      style={{ marginBottom: "16px" }}
                      onClick={() => handleColorClick(color)}
                    >
                      {renderColorSwatch(color)}
                      {/* <div style={{textAlign: 'center'}}>{color}</div> */}
                    </Col>
                  ))}
                </Row>
              </Modal>

              {/* Scent modal */}
              <Modal
                title="Available Scents"
                visible={scentModalVisible}
                onCancel={hideScentModal}
                footer={null}
              >
                <Row gutter={16}>
                  {scents.available.map((scent) => renderScentCard(scent))}
                </Row>

                  {/* Display variants in a table format */}
                  <h3>Variants</h3>
                  <hr></hr>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Variant</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.variants.map((variant) => (
                        <tr key={variant.id}>
                          <td>{variant.name}</td>
                          <td>NRs. {variant.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              </Modal>
            </Card>

            <Button
              type="primary"
              className="rounded-button"
              style={{ width: "100%", marginTop: "1rem" }}
              onClick={() => handleAddToCart(product)}
            >
              {added ? 'Added' : 'Add to Cart'}
            </Button>
            <Button
              type="secondary"
              className="rounded-button"
              style={{ width: "100%", marginTop: "1rem" }}
              onClick={viewCart}
            >
              Go to Cart
            </Button>
          </Col>

          {/* Similar Product Section */}
          <Col  xs={24} sm={24} md={8}>
            <Card className="product-detail-card">
              <Meta title="Similar Products"></Meta>
              <br/>
              {getMultipleRandom(products, 4).map((pr) =><>
              <Row gutter={16} hoverable onClick={() => handleSimilarProductClick(pr.id)}>
                <Col>
                  <img 
                    alt={pr.name} 
                    src={pr.image} 
                    style={{maxWidth: '80px', borderRadius: '1rem'}} 
                  />
                </Col>
                <Col>
                  <h3>{pr.name}</h3>
                  <small>NRs.</small> <strong style={{padding: '1rem 0'}}>{pr.price} </strong>
                  <div>{pr.caption.slice(0, 40)}...</div>
                </Col>
              </Row>
              <br/>
              <hr/>
              <br/>
              </>)}

            </Card>
          </Col>
          {/* Similar Product Section End */}


        </Row>
      </div>
    </>
  );
};

export default ProductDetailPage;
